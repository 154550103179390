/*
 * Sidebar
 */

@media (min-width: 768px) {
  .sidebar .offcanvas-lg {
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
  }

  .navbar-search {
    display: block;
  }
}

.sidebar-heading {
  color: #fff !important;
  font-weight: bold;
  font-size: .75rem;
}

.sidebaradmin {
  background: rgb(19, 31, 45) !important;
  position: fixed;
}

.sidebaradmin a {
  font-size: .90rem;
  display: block;
  padding: 8px 10px 8px 16px;
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
}

.sidebaradmin .nav-link:focus,
.sidebaradmin .active,
.sidebaradmin a:hover {
  background-color: #E6E9F2;
  border-radius: 10px;
  color: rgb(19, 31, 45) !important;
}

/* .nav-link:focus */
/* .sidebaradmin .nav-link:hover {
  color: rgb(19, 31, 45) !important;
} */

.sidebaradmin .nav-link {
  padding-left: 10px !important;
}

.sidebaradmin .nav-item {
  padding-left: 10px !important;
  padding-right: 10px !important;
  transition: background-color 0.3s ease, border-radius 0.3s ease;
}

.content-page {
  background-color: #E6E9F2;
}

.sidebaradmin a:hover::after {
  content: '';
}

.paginator-button {
  width: 25px;
  height: 25px;
}


@media(max-width: 991px) {
  .sidebaradmin {
    backdrop-filter: unset !important;
    z-index: 1021;
  }

  .offcanvas-md {
    background-color: rgb(19, 31, 45) !important;
  }
}