.btn-wsp {
    position: fixed;
    width: 60px;
    height: 60px;
    line-height: 63px;
    bottom: 30px;
    right: 35px;
    background: rgb(12 90 41);
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 35px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
    z-index: 100;
    transition: all 300ms ease;
    transition: ease 1s;
    transition: ease 0.3s;
    animation: efecto 1.2s infinite;
}

.btn-wsp:hover {
    text-decoration: none;
    color: #fff;
    background: rgb(32, 186, 90);
    transform: scale(1.1);
    transition: 0.3s;
}

.btn-wsp:visited {
    text-decoration: none;
    color: #fff;
    background: rgb(12 90 41);
    transform: scale(1.1);
    transition: 0.3s;
}

@keyframes efecto {
    0% {
        box-shadow: 0 0 0 0 rgba(32, 186, 90, 0.85);
    }

    100% {
        box-shadow: 0 0 0 25px rgba(241, 238, 238, 0);
    }
}

@media only screen and (min-width:320px) and (max-width:768px) {
    .btn-wsp {
        width: 63px;
        height: 63px;
        line-height: 66px;
    }
}