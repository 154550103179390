/* devanagari */
/* @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
} */

/* latin-ext */
/* @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
} */

/* latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


body,
html {
    font-family: 'Poppins';
    scroll-padding-top: 60px;
}

.sectionOne {
    /* height: 700px; */
    background: linear-gradient(rgba(19, 31, 45, 0.8), rgba(19, 31, 45, 0.8)), url('../img/bg.jpg') center center / cover no-repeat;
}

.sectionTwo {
    background: #fff;
}

.sectionThree {
    background-color: #f5f5f5;
}

.sectionFour {
    background: linear-gradient(rgba(19, 31, 45, 0.8), rgba(19, 31, 45, 0.8)), url('../img/bg4.jpg') center center / cover no-repeat;
}

.sectionFive {
    background: url('../img/bg5.jpg') center center / cover no-repeat;
}

.sectionSix {
    background: linear-gradient(rgba(19, 31, 45, 0.8), rgba(19, 31, 45, 0.8)), url('../img/bg6.jpg') center center / cover no-repeat;
}

.sectionSeven {
    background-color: #fff;
}

.sectionEigth {
    background-color: rgb(19, 31, 45);
}

.fixedArea {
    position: fixed;
    left: 0%;
    top: 0px;
    z-index: 9;
    width: 100%;
}

.parrafo {
    font-size: 0.6em;
    color: rgb(104, 104, 104);
    text-align: justify;
}

.parallax-mirror {
    height: 100vh;
    background: linear-gradient(rgba(231, 233, 238, 0), rgba(231, 233, 238, 0)), url('../img/bg3.jpg') center center / cover no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.cardInfo {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin: 30px;
}

.contentCardInfo {
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    max-width: 800px;
    padding: 50px 60px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    word-break: break-word;
    border-radius: 10px;
}

/*
* ==========================================
* NAVBAR START
* ==========================================
*/

.navbar.active {
    background: rgb(19, 31, 45) !important;
    border-radius: 0px;
    padding-bottom: 10px;
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}

.myNavBar {
    background-color: #00000000 !important;
    border-color: #e7e7e700;
    padding-bottom: 10px !important;
    padding-top: 10px;
}


/*
  * ==========================================
  * NAVBAR END
  * ==========================================
  */

/*
  * ==========================================
  * PROFILE CARD START
  * ==========================================
  */

.profile-card {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.image-container {
    display: grid;
    place-items: center;
    height: 100%;
}

.profile-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    transition: transform 0.3s;
}

.profile-card:hover .profile-image {
    transform: scale(1.05);
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgb(255 255 255 / 0%), rgb(0 0 0));
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    opacity: 0;
    height: 100px;
    align-items: end;
}

.profile-card:hover .overlay {
    opacity: 1;
}

.text-container {
    transform: translateY(100%);
    transition: transform 0.5s ease;
}

.profile-card:hover .text-container {
    transform: translateY(0);
}

.text-container>h2 {
    font-weight: bold;
    font-size: 0.5em;
    line-height: 0.5;
    text-align: center;
}

.text-container>p {
    font-size: 0.4em;
    text-align: center;
}

/*
  * ==========================================
  * PROFILE CARD END
  * ==========================================
  */
.footer-icon {
    width: 25px;
    height: 25px;
    border: 8px solid #B1802D;
    border-radius: 15px;
    text-align: center;
}


.accordion-button:not(.collapsed) {
    color: #fff !important;
    background-color: rgb(19, 31, 45) !important;
}

.accordion-button:not(.collapsed)::after {
    filter: invert(100%) sepia(100%) hue-rotate(-185deg) saturate(1);
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgb(19 31 45 / 71%);
}

.btn-primary {
    background-color: rgb(19, 31, 45) !important;
    border-color: rgb(19, 31, 45) !important;
}

.sectionForm {
    background-color: rgba(248, 249, 250, 1) !important;
}

@media(max-width: 991px) {
    .sidebar {
        background-color: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(10px);
    }
}

/*
  * ==========================================
  * CARDS START
  * ==========================================
  */

.card {
    width: 100%;
    border-radius: 10px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    /* margin: 20px auto; */
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    background-color: #131f2d !important;
    border-bottom: 1px solid #e0e0e0;
}

.card-header h3 {
    margin: 0;
    font-size: 18px;
    color: #fff;
}

.card-options {
    font-size: 20px;
    color: #999;
    cursor: pointer;
}

.card-body {
    padding-top: 20px;
}

.accordion-button::after {
    margin-left: 10px;
    /* Ajusta el margen del icono del acordeón si es necesario */
}

.accordion-button .bi-check {
    padding-right: 10px;
    /* Ajusta el espaciado si es necesario */
}

.bd-callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #e9ecef;
    border-left-width: .25rem;
    border-radius: .25rem;
}

.bd-callout-info {
    border-left-color: #5bc0de;
}

.bd-callout>h5 {
    font-weight: bold;
    font-size: 1.1rem;
}

.text-gold {
    --bs-text-opacity: 1;
    color: rgb(177 128 45) !important;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    color: #fff !important;
    background-color: rgb(19, 31, 45) !important;
    border-color: rgb(19, 31, 45) !important;
}

.btn-outline-primary {
    color: rgb(19, 31, 45) !important;
    border-color: rgb(19, 31, 45) !important;
}

.btn:hover {
    color: #fff !important;
    background-color: rgb(19, 31, 45) !important;
    border-color: rgb(19, 31, 45) !important;
}